p {
  margin-bottom: 0px !important;
}
a {
  text-decoration: none !important;
}
.feed-section {
  display: flex;
  height: 100vh;
  overflow-y: hidden;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2px;
  padding: 0px 0 0px 0;
  font-size: 20px;
  background-color: #001d3d;
}
/* .feed-container {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  height: 100vh;
} */
.feed-part1 {
  height: 100%;
  display: flex;
  padding: 0px 20px;
  flex-direction: column;
  width: 75%;
  overflow-y: auto;
}

.rp-heading {
  display: flex;
  padding: 12px 0;
  font-family: "Poppins", sans-serif;
  font-size: 32px;
  font-weight: 600;
  color: #003580;
}
.rp-cards {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
/* Feed Cards CSS Start */

/* Feed Card CSS End */
/* Top Predictors CSS Start */

.top-predictors-header,
.trending-predictions-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
.tp-cards {
  display: flex;
  gap: 10px;
  width: 100%;
  overflow-x: auto;
}
.tp-cards::-webkit-scrollbar {
  display: none;
}
/* Top Predictors CSS Ends */
@media (max-width: 1023px) {
  .feed-section {
    width: 100%;
    height: 100%;
  }
  /* .feed-container {
    flex-direction: column;
    height: 100%;
    width: 100%;
  } */
  .feed-part1 {
    height: 100%;
    width: 100%;
    padding: 16px;
  }
  .feed-part2 {
    display: none;
  }
  .rp-heading {
    padding: 0px 0px 10px 0px;
  }
}

@media (max-width: 768px) {
  .feed-part1 {
    height: 100%;
    width: 100%;
    padding: 0px;
  }
  .rp-heading {
    padding: 10px 16px;
  }
  .feed-card {
    padding: 16px;
    border-radius: 0px;
    background: #ffffff20;
  }
  .profile-name h5 {
    color: #fff;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .card-header > .card-header-btn {
    font-size: 14px;
    padding: 8px 24px;
  }
  .card-footer {
    width: 100%;
  }
  .top-predictors-header,
  .rp-heading,
  .trending-predictions-header {
    padding: 10px 16px;
    font-size: 16px;
  }
  .tp-cards {
    padding: 0px 16px;
  }
}
