@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .mask-image-radial-fade {
    mask-image: radial-gradient(rgb(0, 0, 0), transparent 50%);
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.landing {
  /* font-family: "Plus Jakarta Sans", sans-serif; */
  font-optical-sizing: auto;
  scroll-behavior: smooth;
}

@font-face {
  font-family: "Calibre";
  src: url("../src/assets/fonts/calibre/TestCalibre-Regular-BF661746edbee52.otf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Calibre";
  src: url("../src/assets/fonts/calibre/TestCalibre-Semibold-BF661746ed9242e.otf")
    format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Calibre";
  src: url("../src/assets/fonts/calibre/TestCalibre-Bold-BF661746ed7c530.otf")
    format("truetype");
  font-weight: bold;
  font-style: normal;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(128, 128, 128, 0.193);
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(199, 199, 199);
  border-radius: 4px;
}
.example::-webkit-scrollbar {
  display: none;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* SideNav Styles Start */

/* SideNav Styles Ends */

/*Share Modal Ant design Modal Styles Starts*/
.share-modal .ant-modal .ant-modal-content {
  background-color: #262626;
  padding: 50px;
}
.ant-modal .ant-modal-header {
  background: #262626;
}
.ant-modal .ant-modal-title {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.ant-modal .ant-modal-footer {
  display: none;
}
.share-modal .ant-modal .ant-modal-close {
  top: 48px;
  right: 50px;
}
.share-modal .anticon {
  padding: 4px;
  border-radius: 10px;
  border: 2px solid white;
}
.anticon svg {
  display: inline-block;
  font-size: 20px;
  color: white;
}

/* Add Channel Modal Style Starts */
.ant-modal .ant-modal-content {
  background-color: #262626 !important;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root,
.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused,
.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon,
.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
  color: white !important;
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline,
.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: white !important;
}
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 14px 8px !important;
  color: white !important;
}

.tab_subheading::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.tab_subheading {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
