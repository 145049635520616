.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-logo img {
  height: 20px;
}

.navbar-toggle {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
  z-index: 1000;
}

.navbar-toggle-icon {
  display: block;
  width: 20px;
  height: 2px;
  background-color: white;
  position: relative;
}

.navbar-toggle-icon::before,
.navbar-toggle-icon::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-color: white;
  position: absolute;
}

.navbar-toggle-icon::before {
  top: -6px;
}

.navbar-toggle-icon::after {
  bottom: -6px;
}

.navbar-nav {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-item {
  margin-left: 20px;
}

.nav-link {
  text-decoration: none;
  color: #747474;
  font-size: 14px;
}

.nav-link.active {
  color: white;
}

.signup-btn {
  background-color: transparent;
  color: #ffffff;
  padding: 8px 24px;
  border-radius: 24px;
  border: 2px solid #545454;
}

.signup-btn.active {
  background-color: #4b6cc2;
  border: 2px solid #4b6cc2;
  color: #ffffff;
}

@media (max-width: 1024px) {
  .navbar {
    padding: 16px;
  }
}
@media (max-width: 768px) {
  .navbar-toggle {
    display: block;
    z-index: 1001;
  }

  .navbar-nav {
    display: none;
    position: fixed;
    top: 0;
    right: -200px;
    width: 200px;
    height: 100vh;
    background-color: #0b0b0f;
    padding: 20px;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    transition: right 0.3s ease-in-out;
    z-index: 1000;
  }

  .navbar-nav.open {
    display: block;
    right: 0;
  }

  .nav-item {
    margin: 10px 0;
  }
}
