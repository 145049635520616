/* src/LandingPage.css */
.gradient-text {
    background: linear-gradient(45deg, #ff6ec4, #7873f5, #4facfe);
    background-size: 200% 200%;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    animation: gradientAnimation 3s ease infinite;
  }
  
  @keyframes gradientAnimation {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }
  